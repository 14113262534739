<template>
    <div class="invoice-container">
        <div class="box-border pv4" style="background: #fbfcfe">
            <div class="w-90-l w-100 center box-border">
                <div class="pa4">
                    <header class="flex items-center justify-between">
                        <h1>INVOICE</h1>
                        <div>
                            <div ref="companyLogoWrapper" v-if="orgImage">
                                <img
                                    ref="companyLogo"
                                    :src="orgImage"
                                    alt=""
                                    style="object-position: center; object-fit: cover; width: 60px; height: 60px"
                                    class="rounded-circle"
                                />
                            </div>
                            <div v-else class="org-img-placeholder">
                                <span>{{ orgInitials || userInitials || '' }}</span>
                            </div>
                        </div>
                    </header>
                    <section class="flex pt2" style="justify-content: flex-end; text-align: right">
                        <div>
                            <p class="b" :style="'color: ' + color">{{ invoice.business?.name }}</p>
                            <p>{{ invoice.business?.address }}</p>
                            <p>{{ invoice.business?.email }}</p>
                            <p>{{ invoice.business?.phone }}</p>
                            <p v-if="invoice.business?.tin">TIN: {{ invoice.business?.tin }}</p>
                            <p v-if="invoice.business?.rcNumber">RC No: {{ invoice.business?.rcNumber }}</p>
                        </div>
                    </section>
                    <section class="pt4 flex flex-wrap mb-wrap justify-between">
                        <div>
                            <p class="b">Bill to</p>
                            <p class="b" :style="'color: ' + color">{{ invoice.customer?.name }}</p>
                            <p>{{ invoice.customer?.address }}</p>
                            <p>{{ invoice.customer?.country }}</p>
                            <p>{{ invoice.customer?.email }}</p>
                            <p>{{ invoice.customer?.phone }}</p>
                        </div>

                        <div class="flex" style="gap: 10px">
                            <div>
                                <p class="b">Invoice #</p>
                                <p class="b">Issued Date</p>
                                <p class="b">Due Date</p>
                            </div>
                            <div>
                                <p>{{ invoice.number }}</p>
                                <p>{{ formatDate(invoice.dateIssued) }}</p>
                                <p>{{ formatDate(invoice.dateDue) }}</p>
                            </div>
                        </div>
                    </section>
                    <div v-if="invoice?.description && invoice.description != ''" class="mt3 b">
                        {{ invoice.description }}
                    </div>
                    <section class="scrollmenu">
                        <table class="inv3-table">
                            <thead>
                                <tr
                                    class=""
                                    :style="'border-bottom: 2px solid ' + color + '; border-top: 2px solid ' + color + ';'"
                                >
                                    <th class="">QTY</th>
                                    <th class="">DESCRIPTION</th>
                                    <th class="rw-r mbCosts">UNIT PRICE</th>
                                    <th class="rw-r mbCosts">AMOUNT</th>
                                </tr>
                            </thead>
                            <tbody class="inv-tbody">
                                <tr v-for="item of invoice.items" v-bind:key="item.id">
                                    <td class="mbWidth">{{ item.quantity }} {{ item.measurement }}</td>
                                    <td  class="w-40-l w-70 mbCost"  style="white-space: pre-wrap;">{{ item.name }}</td>
                                    <td class="rw-r  mbCost">{{ item.price }}</td>
                                
                                    <!-- <td class="rw-r mbCost" v-if="item.discount > 0">
                                        <div class="text-muted" style="text-decoration: line-through">
                                            {{
                                                formatAmount(
                                                    item.price * item.quantity,
                                                    invoice.currency || $store.state.Settings?.currency
                                                )
                                            }}
                                        </div>
                                        <div>
                                            {{ formatAmount(item.total, invoice?.currency || $store.state.Settings?.currency) }}
                                        </div>
                                    </td> -->
                                    <td class="rw-r  mbCost" v-if="item.discount > 0">
                                        <div class="text-muted" style="text-decoration: line-through;">
                                            {{
                                                formatAmount(
                                                    item.price * item.quantity,
                                                    invoice.currency || $store.state.Settings?.currency
                                                )
                                            }}
                                        </div>
                                        <div>
                                            {{ formatAmount(item.total, invoice?.currency || $store.state.Settings?.currency) }}
                                        </div>
                                    </td>

                                    <td class="rw-r" v-else>
                                        {{
                                            formatAmount(
                                                item.price * item.quantity,
                                                invoice?.currency || $store.state.Settings?.currency
                                            )
                                        }}
                                    </td>
                                </tr>
                                <tr>
                                    <td class="w-25"></td>
                                    <td class="w-25"></td>
                                    <td class=" rw-r b"></td>
                                    <td class="w-25 rw-r" style="width: 100%;">
                                       
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </section>
                    <section class="deskView w-100" >
                        <table class="w-100" cellspacing="0">
                            <tfoot class="inv3-tf">
                                <tr style="border-top: 1px solid #e3e8ee">
                                    <td class="w-25"></td>
                                    <td class="w-25"></td>
                                    <td class=" rw-r b">Subtotal</td>
                                    <td class="w-25 rw-r tdPad2" style="width: 100%;">
                                        {{ formatAmount(invoice.subtotal, invoice?.currency || $store.state.Settings?.currency) }}
                                    </td>
                                </tr>
                                <tr v-if="invoice.hasDiscount">
                                    <td class="w-25"></td>
                                    <td class="w-25"></td>
                                    <td class=" rw-r b">Discount</td>
                                    <td class="w-25 rw-r" v-if="invoice.discountType == 'fixed'" style="color: #D2042D">
                                        - {{ formatAmount(invoice.discount, invoice?.currency || $store.state.Settings?.currency) }}
                                    </td>
                                    <td class="rw-r" v-else style="color: #D2042D">-{{ invoice.discount }}%</td>
                                </tr>
                                <tr v-if="invoice.hasCommission">
                                    <td class="w-25"></td>
                                    <td class="w-25"></td>
                                    <td class=" rw-r b">Commission</td>
                                    <td class="w-25 rw-r" v-if="invoice.commissionType == 'fixed'">
                                        {{
                                            formatAmount(invoice.commission, invoice?.currency || $store.state.Settings?.currency)
                                        }}
                                    </td>
                                    <td v-else class="rw-r">{{ invoice.commission }}%</td>
                                </tr>
                                <tr v-if="invoice.hasShipping">
                                    <td></td>
                                    <td></td>
                                    <td class="rw-r b">Shipping</td>
                                    <td v-if="invoice.shippingType == 'fixed'" class="rw-r">
                                        {{ formatAmount(invoice.shipping, invoice?.currency || $store.state.Settings?.currency) }}
                                    </td>
                                    <td v-else class="rw-r">{{ invoice.shipping }}%</td>
                                </tr>
                                <tr v-if="invoice.hasOthersCharges">
                                    <td></td>
                                    <td></td>
                                    <td class="rw-r b">{{ invoice.others || 'Other Charges' }}</td>
                                    <td class="rw-r">
                                        {{
                                            formatAmount(
                                                invoice.othersCharges,
                                                invoice?.currency || $store.state.Settings?.currency
                                            )
                                        }}
                                    </td>
                                </tr>
                                <tr v-if="invoice.hasTax">
                                    <td></td>
                                    <td></td>
                                    <td class="rw-r b">Tax</td>
                                    <td v-if="invoice.taxType == 'fixed'" class="rw-r">
                                        {{ formatAmount(invoice.tax, invoice?.currency || $store.state.Settings?.currency) }}
                                    </td>
                                    <td class="rw-r" v-else>{{ invoice.tax }}%</td>
                                </tr>
                                <!-- <tr class="b">
                                    <td></td>
                                    <td></td>
                                    <td class="w-25 rw-r">Total</td>
                                    <td class="w-25 rw-r" style="width: 100%;">
                                        {{
                                            formatAmount(
                                                invoice.totalAmount,
                                                invoice?.currency || $store.state.Settings?.currency
                                            )
                                        }}
                                    </td>
                                </tr> -->
                                <tr>
                                    <td class="w-25"></td>
                                    <td class="w-25"></td>
                                    <td class=" rw-r b">Total</td>
                                    <td class="w-25 rw-r tdPad2">
                                        {{
                                            formatAmount(
                                                invoice.totalAmount,
                                                invoice?.currency || $store.state.Settings?.currency
                                            )
                                        }}
                                    </td>
                                </tr>

                                <tr class="b" v-if="invoice?.deposit && invoice?.deposit > 0">
                                    <td></td>
                                    <td></td>
                                    <td class="rw-r">Amount Paid</td>
                                    <td class="rw-r">
                                        {{
                                            formatAmount(
                                                invoice?.deposit || 0,
                                                invoice?.currency || $store.state.Settings?.currency
                                            )
                                        }}
                                    </td>
                                </tr>
                                <tr class="b" v-if="invoice?.deposit && invoice?.deposit > 0">
                                    <td></td>
                                    <td></td>
                                    <td class="rw-r text-danger">Outstanding</td>
                                    <td class="rw-r text-danger">
                                        {{
                                            formatAmount(
                                                invoice.totalAmount - (invoice?.deposit || 0),
                                                invoice?.currency || $store.state.Settings?.currency
                                            )
                                        }}
                                    </td>
                                </tr>
                            </tfoot>
                        </table>
                    </section>
                    <div class="clear-both"></div>
                    <section class="mt4">
                        <p class="b" :style="'color: ' + color">Thank you!</p>
                        <div v-if="invoice.notes">
                            <p class="b pv2" :style="'color: ' + color">Notes</p>
                            <p>{{ invoice.notes ? invoice.notes : '' }}</p>
                        </div>
                        <div class="pt2">
                            <p class="b pv2" :style="'color: ' + color">Terms and conditions</p>
                            <p>
                                All amounts are in {{ getCurrencyName(invoice?.currency || 'NGN') }}. Please make the payment
                                before {{ formatDate(invoice.dateDue) }}.
                            </p>
                        </div>
                    </section>
                </div>
            </div>
        </div>
    </div>
   
    <div v-if="invoice?.paymentInfo" class="box-border mv4" style="background: #fbfcfe">
        <div class="flex justify-between box-border-bottom pa3">
            <div>Previous Payment(s)</div>
        </div>
        <div v-for="(data, index) of invoice.paymentInfo" v-bind:key="index" class="pa3" style="border-bottom: 1px solid #e3e8ee">
            <div class="flex justify-between">
                <div class="w-30">{{ data.account }}</div>
                <div class="w-30 b">
                    {{ formatAmount(data.amount, invoice?.currency || $store.state.Settings?.currency) }}
                </div>
                <div class="w-30 b">{{ data?.date ? formatDate(data.date) : '' }}</div>
            </div>
        </div>
    </div>
    <div class="box-border mv4" style="background: #fbfcfe">
        <div class="flex justify-between box-border-bottom pa3">
            <div>Selected payment option</div>
        </div>
        <div v-for="item of invoice.bankDetails" v-bind:key="item.bankAccountNumber" class="pa3">
            <div class="flex flex-wrap justify-between items-center pa3 box-border">
                <div>
                    <div class="pb2 b">Bank Transfer</div>
                    <div class="pb2">Pay by making a bank transfer to the recievers bank account</div>
                    <div class="flex justify-between">
                        <div class="w-30">Bank Name:</div>
                        <div class="w-70 b">{{ item.bankName }}</div>
                    </div>
                    <div class="flex justify-between">
                        <div class="w-30">Account Number:</div>
                        <div class="w-70 b">{{ item.bankAccountNumber }}</div>
                    </div>
                    <div class="flex justify-between">
                        <div class="w-30">Account Name:</div>
                        <div class="w-70 b">{{ item.bankAccountName }}</div>
                    </div>
                </div>
                <div class="pt0-l pt3">
                    <img src="imgs/bank.svg" alt="" />
                </div>
            </div>
        </div>
        <div v-if="invoice?.paystackLink" class="pa3">
            <div class="flex flex-wrap justify-between items-center pa3 box-border copy-text-wrapper">
                <div>
                    <div class="pb2 b">Paystack</div>
                    <div>Pay through the secure paystack API</div>
                    <div class="flex flex-wrap justify-between items-center pb2">
                        <a :href="invoice.paystackLink" target="_blank" class="pr2">{{ invoice.paystackLink }}</a>
                        <button @click="doCopy" class="pl0 pr0 pb0 pt0">
                            <span class="icon">
                                <font-awesome-icon icon="fa-solid fa-copy" />
                            </span>
                        </button>
                    </div>
                </div>
                <div class="pt0-l pt3">
                    <img :src="require('@/assets/images/paystack.svg')" alt="paystack logo" />
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { useStore } from 'vuex'
import { computed } from 'vue'
import InvoiceStatus from '@/views/Invoice/widgets/InvoiceStatus'
import { formatAmount, getCurrencyName, getDaysFromToday, formatDate } from '@/utils/lib'

export default {
    name: 'SilverInvoice',
    components: { InvoiceStatus },
    props: {
        invoice: { type: Object },
        color: { type: String },
        font: { type: String },
    },
    setup() {
        const store = useStore()

        const orgData = computed(() => store?.state?.Auth?.userData)
        const orgInitials = computed(() => store?.state?.Auth?.orgInitials)
        const orgImage = computed(() => store?.state?.Auth?.orgImage)
        const userInitials = computed(() => store?.state?.Auth?.userInitials)

        return {
            orgData,
            orgInitials,
            orgImage,
            userInitials,
            formatAmount,
            getCurrencyName,
            getDaysFromToday,
            formatDate,
        }
    },
}
</script>
<style scoped>
.inv3-table {
    margin-top: 10px;
    width: 100%;
    text-align: left;
    border-collapse: collapse;
}
.inv3-table tbody tr td {
    padding: 20px 0;
}
.inv3-tf tr td {
    padding-top: 20px;
}
.deskView{
    float: right;
}
.deskView table{
    border-collapse: collapse;
}
.clear-both{
    clear: both;
}
.inv-tbody .mbWidth{
        padding: 0 30px 0 0;
    }
@media screen and (max-width: 520px) {
    .mb-wrap div {
        margin-top: 10px;
    }
    .mbCost{
        padding: 0 30px;
    }
    .mbCosts{
        padding: 0 0 0 30px;
    }
    .inv-tbody .mbWidth{
        padding: 0 30px 0 0;
    }
}
@media screen and (max-width: 630px) {
    .tdPad {
    padding-right: 20px;
}
.tdPad2{
    padding-left: 20px;
}
.mbUnit{
    text-align: left;
}
}
</style>
