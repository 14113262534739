<template>
    <div class="invoice-container">
        <div class="box-border pv4" style="background: #fbfcfe">
            <div class="w-90-l w-100 center box-border">
                <div class="pa4">
                    <div class="flex flex-wrap justify-between items-center">
                        <div ref="companyLogoWrapper" v-if="orgImage">
                            <img
                                ref="companyLogo"
                                :src="orgImage"
                                alt=""
                                style="object-position: center; object-fit: cover; width: 60px; height: 60px"
                                class="rounded-circle"
                            />
                        </div>
                        <div v-else class="org-img-placeholder">
                            <span>{{ orgInitials || userInitials || '' }}</span>
                            <div class="flex flex-wrap justify-between pv3">
                                <div class="font-w1 pb3 pb0-l"></div>
                            </div>
                        </div>

                        <section>
                            <h5 style="text-transform: uppercase" class="b">{{ invoice.business?.name }}</h5>
                            <p>{{ invoice.business?.address }}</p>
                            <p>{{ invoice.business?.email }}</p>
                            <p>{{ invoice.business?.phone }}</p>
                            <p v-if="invoice.business?.tin">TIN: {{ invoice.business?.tin }}</p>
                            <p v-if="invoice.business?.rcNumber">RC No: {{ invoice.business?.rcNumber }}</p>
                        </section>
                    </div>

                    <section class="justify-between pt4 flex flex-wrap">
                        <div>
                            <p class="b" :style="'color: ' + color">Billed To</p>
                            <p>{{ invoice.customer?.name }}</p>
                            <p>{{ invoice.customer?.address }}</p>
                            <p>{{ invoice.customer?.country }}</p>
                            <p>{{ invoice.customer?.email }}</p>
                            <p>{{ invoice.customer?.phone }}</p>
                        </div>

                        <div>
                            <div>
                                <p class="b" :style="'color: ' + color">Date Issued</p>
                                <p>{{ formatDate(invoice.dateIssued) }}</p>
                            </div>

                            <div class="pv2">
                                <p class="b" :style="'color: ' + color">Due Date</p>
                                <p>{{ formatDate(invoice.dateDue) }}</p>
                            </div>
                        </div>

                        <div>
                            <p class="b" :style="'color: ' + color">Invoice Number</p>
                            <p>{{ invoice.number }}</p>

                            <div class="pv2">
                                <p class="b" :style="'color: ' + color">Amount Due</p>
                                <p>
                                    {{ formatAmount(invoice.totalAmount, invoice?.currency || $store.state.Settings?.currency) }}
                                </p>
                            </div>
                        </div>
                    </section>

                    <div v-if="invoice?.description && invoice.description != ''" class="mt3 b">
                        {{ invoice.description }}
                    </div>

                    <section class="pt4 scrollmenu">
                        <table class="invoice_table" style="width: 100%; border-collapse: collapse">
                            <thead>
                                <th :style="'color: ' + color + '; border-top:1px solid' + color + ';'">Description</th>
                                <th :style="'color: ' + color + '; border-top:1px solid' + color + ';'" class="rw-r">Rate</th>
                                <th
                                    :style="'color: ' + color + '; border-top:1px solid' + color + ';'"
                                    class="rw-r mb-view"
                                    style="width: 100px"
                                >
                                    Qty/Unit
                                </th>
                                <th :style="'color: ' + color + '; border-top:1px solid' + color + ';'" class="rw-r">Amount</th>
                            </thead>
                            <tbody>
                                <tr class="top added-p w-100" v-for="item of invoice.items" v-bind:key="item.id">
                                    <td class="w-40-l w-70 "  style="white-space: pre-wrap;">{{ item.name }}</td>
                                    <td class="rw-r">{{ item.price }}</td>
                                    <td class="rw-r mb-view" style="width: 100px">{{ item.quantity }} {{ item.measurement }}</td>
                                    <td class="rw-r" v-if="item.discount > 0">
                                        <div class="text-muted" style="text-decoration: line-through;">
                                            {{
                                                formatAmount(
                                                    item.price * item.quantity,
                                                    invoice.currency || $store.state.Settings?.currency
                                                )
                                            }}
                                        </div>
                                        <div>
                                            {{ formatAmount(item.total, invoice?.currency || $store.state.Settings?.currency) }}
                                        </div>
                                    </td>

                                    <td class="rw-r" v-else>
                                        {{
                                            formatAmount(
                                                item.price * item.quantity,
                                                invoice?.currency || $store.state.Settings?.currency
                                            )
                                        }}
                                    </td>
                                </tr>
                                <tr>
                                    <td class="rw-r"></td>
                                    <td class="rw-r"></td>
                                    <td class="rw-r"></td>
                                    <td class="rw-r">
                                        
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </section>
                    <section class="w-100">
                        <table class="w-100 collapse" style="width: 100%; border-collapse: collapse" cellspacing="0">
                            <tbody>
                                <!-- <tr >
                                    <td style="border:1px solid red" class="rw-r"></td>
                                    <td style="border:1px solid red" class="tr dn-l db">Subtotal</td>
                                    <td style="border:1px solid red" class="rw-r"></td>
                                    <td style="border:1px solid red" class="tr db-l db">Subtotal</td>
                                    <td style="border:1px solid red" class="rw-r">
                                        {{ formatAmount(invoice.subtotal, invoice?.currency || $store.state.Settings?.currency) }}
                                    </td>
                                </tr> -->
                                <tr class="right-alligned-cols" v-if="invoice.hasDiscount">
                                    <td></td>
                                    <td>Subtotal</td>
                                    <td></td>

                                    <td>
                                        {{ formatAmount(invoice.subtotal, invoice?.currency || $store.state.Settings?.currency) }}
                                    </td>
                                </tr>

                                <tr class="right-alligned-cols" v-if="invoice.hasDiscount">
                                    <td></td>
                                    <td>Discount</td>
                                    <td></td>
                                    <td v-if="invoice.discountType == 'fixed'" style="color: #D2042D">
                                       - {{ formatAmount(invoice.discount, invoice?.currency || $store.state.Settings?.currency) }}
                                    </td>
                                    <td v-else style="color: #D2042D">-{{ invoice.discount }}%</td>
                                </tr>

                                <tr class="right-alligned-cols" v-if="invoice.hasCommission">
                                    <td></td>
                                    <td>Commission</td>
                                    <td></td>
                                    <td v-if="invoice.commissionType == 'fixed'">
                                        {{
                                            formatAmount(invoice.commission, invoice?.currency || $store.state.Settings?.currency)
                                        }}
                                    </td>
                                    <td v-else>{{ invoice.commission }}%</td>
                                </tr>
                                <tr class="right-alligned-cols" v-if="invoice.hasShipping">
                                    <td></td>
                                    <td>Shipping</td>
                                    <td></td>
                                    <td v-if="invoice.shippingType == 'fixed'">
                                        {{ formatAmount(invoice.shipping, invoice?.currency || $store.state.Settings?.currency) }}
                                    </td>
                                    <td v-else>{{ invoice.shipping }}%</td>
                                </tr>
                                <tr class="right-alligned-cols" v-if="invoice.hasOthersCharges">
                                    <td></td>
                                    <td>{{ invoice.others || 'Other Charges' }}</td>
                                    <td></td>
                                    <td>
                                        {{
                                            formatAmount(
                                                invoice.othersCharges,
                                                invoice?.currency || $store.state.Settings?.currency
                                            )
                                        }}
                                    </td>
                                </tr>
                                <tr class="right-alligned-cols tax-shift" v-if="invoice.hasTax">
                                    <td></td>
                                    <td class="tax-shift-bordered">Tax</td>
                                    <td class="tax-shift-bordered"></td>
                                    <td class="tax-shift-bordered" v-if="invoice.taxType == 'fixed'">
                                        {{ formatAmount(invoice.tax, invoice?.currency || $store.state.Settings?.currency) }}
                                    </td>
                                    <td class="tax-shift-bordered" v-else>{{ invoice.tax }}%</td>
                                </tr>

                                <tr class="right-alligned-cols">
                                    <td></td>
                                    <td>Total</td>
                                    <td></td>
                                    <td>
                                        {{
                                            formatAmount(
                                                invoice.totalAmount,
                                                invoice?.currency || $store.state.Settings?.currency
                                            )
                                        }}
                                    </td>
                                </tr>

                                <tr class="right-alligned-cols shifted" v-if="invoice?.deposit && invoice?.deposit > 0">
                                    <td></td>
                                    <td :style="'border-bottom:1px solid' + color + ';'">Amount Paid</td>
                                    <td :style="'border-bottom:1px solid' + color + ';'"></td>
                                    <td :style="'border-bottom:1px solid' + color + ';'">
                                        {{
                                            formatAmount(
                                                invoice?.deposit || 0,
                                                invoice?.currency || $store.state.Settings?.currency
                                            )
                                        }}
                                    </td>
                                </tr>
                                <tr class="right-alligned-cols" v-if="invoice?.deposit && invoice?.deposit > 0">
                                    <td></td>
                                    <th class="rw-r text-danger">Outstanding</th>
                                    <td></td>
                                    <th class="rw-r text-danger">
                                        {{
                                            formatAmount(
                                                invoice.totalAmount - (invoice?.deposit || 0),
                                                invoice?.currency || $store.state.Settings?.currency
                                            )
                                        }}
                                    </th>
                                </tr>
                            </tbody>
                        </table>
                    </section>

                    <div class="clear-both"></div>

                    <section class="lh-copy pv3">
                        <div v-if="invoice.notes">
                            <p :style="'color: ' + color">Notes</p>
                            <p>{{ invoice.notes ? invoice.notes : '' }}</p>
                        </div>

                        <div>
                            <p :style="'color: ' + color">Terms</p>
                            <p>
                                All amounts are in {{ getCurrencyName(invoice?.currency || 'NGN') }}. Please make the payment
                                before {{ formatDate(invoice.dateDue) }}.
                            </p>
                        </div>
                    </section>
                </div>
            </div>
        </div>

        <div v-if="invoice?.paymentInfo" class="box-border mv4" style="background: #fbfcfe">
            <div class="flex justify-between box-border-bottom pa3">
                <div>Previous Payment(s)</div>
            </div>
            <div
                v-for="(data, index) of invoice.paymentInfo"
                v-bind:key="index"
                class="pa3"
                style="border-bottom: 1px solid #e3e8ee"
            >
                <div class="flex justify-between">
                    <div class="w-30">{{ data.account }}</div>
                    <div class="w-30 b">
                        {{ formatAmount(data.amount, invoice?.currency || $store.state.Settings?.currency) }}
                    </div>
                    <div class="w-30 b">{{ data?.date ? formatDate(data.date) : '' }}</div>
                </div>
            </div>
        </div>

        <div class="box-border mv4" style="background: #fbfcfe">
            <div class="flex justify-between box-border-bottom pa3">
                <div>Selected payment option</div>
            </div>
            <div v-for="item of invoice.bankDetails" v-bind:key="item.bankAccountNumber" class="pa3">
                <div class="flex flex-wrap justify-between items-center pa3 box-border">
                    <div>
                        <div class="pb2 b">Bank Transfer</div>
                        <div class="pb2">Pay by making a bank transfer to the recievers bank account</div>
                        <div class="flex justify-between">
                            <div class="w-30">Bank Name:</div>
                            <div class="w-70 b">{{ item.bankName }}</div>
                        </div>
                        <div class="flex justify-between">
                            <div class="w-30">Account Number:</div>
                            <div class="w-70 b">{{ item.bankAccountNumber }}</div>
                        </div>
                        <div class="flex justify-between">
                            <div class="w-30">Account Name:</div>
                            <div class="w-70 b">{{ item.bankAccountName }}</div>
                        </div>
                    </div>
                    <div class="pt0-l pt3">
                        <img src="imgs/bank.svg" alt="" />
                    </div>
                </div>
            </div>
            <div v-if="invoice?.paystackLink" class="pa3">
                <div class="flex flex-wrap justify-between items-center pa3 box-border copy-text-wrapper">
                    <div>
                        <div class="pb2 b">Paystack</div>
                        <div>Pay through the secure paystack API</div>
                        <div class="flex flex-wrap justify-between items-center pb2">
                            <a :href="invoice.paystackLink" target="_blank" class="pr2">{{ invoice.paystackLink }}</a>
                            <button @click="doCopy" class="pl0 pr0 pb0 pt0">
                                <span class="icon">
                                    <font-awesome-icon icon="fa-solid fa-copy" />
                                </span>
                            </button>
                        </div>
                    </div>
                    <div class="pt0-l pt3">
                        <img :src="require('@/assets/images/paystack.svg')" alt="paystack logo" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { useStore } from 'vuex'
import { computed } from 'vue'
import InvoiceStatus from '@/views/Invoice/widgets/InvoiceStatus'
import { formatAmount, getCurrencyName, getDaysFromToday, formatDate } from '@/utils/lib'

export default {
    name: 'DiamondInvoice',
    components: { InvoiceStatus },
    props: {
        invoice: { type: Object },
        color: { type: String },
        font: { type: String },
    },
    setup() {
        const store = useStore()

        const orgData = computed(() => store?.state?.Auth?.userData)
        const orgInitials = computed(() => store?.state?.Auth?.orgInitials)
        const orgImage = computed(() => store?.state?.Auth?.orgImage)
        const userInitials = computed(() => store?.state?.Auth?.userInitials)

        return {
            orgData,
            orgInitials,
            orgImage,
            userInitials,
            formatAmount,
            getCurrencyName,
            getDaysFromToday,
            formatDate,
        }
    },
}
</script>

<style>
.invoice_table th {
    text-align: left;
    padding: 1em 0;
}

.top td {
    padding-top: 1em;
}

.added-p td {
    padding-bottom: 0.5em;
}

.added-p {
    border-bottom: solid 1.5px rgb(223, 220, 220);
}

.tax {
    padding-left: 10px;
}

.right-alligned-cols td {
    padding: 0.3em 0;
    text-align: right;
}

.shifted td {
    padding-top: 2.5em !important;
}

.tax-shift td {
    padding-bottom: 0.5em !important;
}

.tax-shift-bordered {
    border-bottom: solid 1.5px rgb(223, 220, 220);
}
.rw-r {
    text-align: right !important;
    width: 100px;
}

.invoice-container {
    background: #fbfcfe;
    padding: 20px;
}
.deskView {
    float: right;
}
.deskView table {
    border-collapse: collapse;
}
.clear-both {
    clear: both;
}

@media screen and (max-width: 768px) {
    .invoice-container {
        padding: 10px;
    }
    .mb-view {
        width: 250px;
        padding: 0 10px;
    }
}
</style>
