<template>
    <div class="invoice-container">
        <div class="box-border pv4" style="background: #fbfcfe">
            <div class="w-90 center box-border">
                <div class="pa4">
                    <div class="flex justify-between pb4">
                        <h1 :style="'color: ' + color">INVOICE</h1>
                        <div>
                            <div ref="companyLogoWrapper" v-if="orgImage">
                                <img
                                    ref="companyLogo"
                                    :src="orgImage"
                                    alt=""
                                    style="object-position: center; object-fit: cover; width: 60px; height: 60px"
                                    class="rounded-circle"
                                />
                            </div>
                            <div v-else class="org-img-placeholder">
                                <span>{{ orgInitials || userInitials || '' }}</span>
                            </div>
                        </div>
                    </div>
                    <div class="flex flex-wrap">
                        <div class="flex space-between bill_to" style="width: 100%">
                            <div class="flex" style="flex-direction: column; width: 100%">
                                <div class="mb4">
                                    <p class="b">Invoice number</p>
                                    <p>{{ invoice.number }}</p>
                                </div>
                                <div class="mb4" style="height: 50px">
                                    <p class="b">Invoice Status</p>
                                    <p class="pt2"><invoice-status :invoice="invoice" /></p>
                                </div>
                                <div class="mb4">
                                    <p class="b">Billed to</p>
                                    <p class="b" :style="'color: ' + color">{{ invoice.customer?.name }}</p>
                                    <p>{{ invoice.customer?.address }}</p>
                                    <p>{{ invoice.customer?.country }}</p>
                                    <p>{{ invoice.customer?.email }}</p>
                                    <p>{{ invoice.customer?.phone }}</p>
                                </div>
                            </div>
                            <div class="bill_from flex" style="flex-direction: column; width: 100%">
                                <div class="mb4">
                                    <p class="b">Date of issue</p>
                                    <p>{{ formatDate(invoice.dateIssued) }}</p>
                                </div>
                                <div class="w-100" style="height: 50px">
                                    <p class="b">Date Due</p>
                                    <p>{{ formatDate(invoice.dateDue) }}</p>
                                </div>
                                <div class="mt4">
                                    <p class="b">Billed from</p>
                                    <p class="b" :style="'color: ' + color">{{ invoice.business?.name }}</p>
                                    <p>{{ invoice.business?.address }}</p>
                                    <p>{{ invoice.business?.email }}</p>
                                    <p>{{ invoice.business?.phone }}</p>
                                    <p v-if="invoice.business?.tin">TIN: {{ invoice.business?.tin }}</p>
                                    <p v-if="invoice.business?.rcNumber">RC No: {{ invoice.business?.rcNumber }}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-if="invoice?.description && invoice.description != ''" class="mt3 b">
                        {{ invoice.description }}
                    </div>
                    <section class="scrollmenu">
                        <table class="inv-table" cellspacing="0">
                            <thead>
                                <tr class="inv-th" :style="'border-bottom:2px solid ' + color">
                                    <th>Description</th>
                                    <th class="rw-r mbCosts">Cost</th>
                                    <th class="rw-r  mbCosts">Qty/Unit</th>
                                    <th class="rw-r  mbCosts">Amount</th>
                                </tr>
                            </thead>
                            <tbody class="inv-tbody">
                                <tr class="w-100" v-for="item of invoice.items" v-bind:key="item.id">
                                    <td class="b w-40-l w-70"  style="white-space: pre-wrap;">{{ item.name }}</td>
                                    <td class="rw-r w-30  mbCost">{{ item.price }}</td>
                                    <td class="rw-r w-30  mbCost">{{ item.quantity }} {{ item.measurement }}</td>
                                    
                                    <td class="rw-r w-30 mbCost" v-if="item.discount > 0">
                                        <div class="text-muted" style="text-decoration: line-through;">
                                            {{
                                                formatAmount(
                                                    item.price * item.quantity,
                                                    invoice.currency || $store.state.Settings?.currency
                                                )
                                            }}
                                        </div>
                                        <div>
                                            {{ formatAmount(item.total, invoice?.currency || $store.state.Settings?.currency) }}
                                        </div>
                                    </td>
                                    <td v-else class="rw-r pa2">
                                        {{
                                            formatAmount(
                                                item.price * item.quantity,
                                                invoice?.currency || $store.state.Settings?.currency
                                            )
                                        }}
                                    </td> 
                                </tr>
                            </tbody>
                        </table>
                    </section>
                    <section class="deskView">
                        <table>
                            <tfoot class="inv-tfoot">
                                <tr :style="'border-top:2px solid ' + color">
                                    <td></td>
                                    <td></td>
                                    <th class="rw-r">Subtotal</th>
                                    <td class="rw-r">
                                        {{ formatAmount(invoice.subtotal, invoice?.currency || $store.state.Settings?.currency) }}
                                    </td>
                                </tr>
                                <tr v-if="invoice.hasDiscount">
                                    <td></td>
                                    <td></td>
                                    <th class="rw-r">Discount</th>
                                    <td v-if="invoice.discountType == 'fixed'" class="rw-r" style="color: #D2042D">
                                       - {{ formatAmount(invoice.discount, invoice?.currency || $store.state.Settings?.currency) }}
                                    </td>
                                    <td class="rw-r" v-else style="color: #D2042D">-{{ invoice.discount }}%</td>
                                </tr>
                                <tr v-if="invoice.hasCommission">
                                    <td></td>
                                    <td></td>
                                    <th class="rw-r">Commission</th>
                                    <td v-if="invoice.commissionType == 'fixed'" class="rw-r">
                                        {{
                                            formatAmount(invoice.commission, invoice?.currency || $store.state.Settings?.currency)
                                        }}
                                    </td>
                                    <td v-else class="rw-r">{{ invoice.commission }}%</td>
                                </tr>
                                <tr v-if="invoice.hasShipping">
                                    <td></td>
                                    <td></td>
                                    <th class="rw-r">Shipping</th>
                                    <td v-if="invoice.shippingType == 'fixed'" class="rw-r">
                                        {{ formatAmount(invoice.shipping, invoice?.currency || $store.state.Settings?.currency) }}
                                    </td>
                                    <td v-else class="rw-r">{{ invoice.shipping }}%</td>
                                </tr>
                                <tr v-if="invoice.hasOthersCharges">
                                    <td></td>
                                    <td></td>
                                    <th class="rw-r">{{ invoice.others || 'Other Charges' }}</th>
                                    <td class="rw-r">
                                        {{
                                            formatAmount(
                                                invoice.othersCharges,
                                                invoice?.currency || $store.state.Settings?.currency
                                            )
                                        }}
                                    </td>
                                </tr>
                                <tr v-if="invoice.hasTax">
                                    <td></td>
                                    <td></td>
                                    <th class="rw-r">Tax</th>
                                    <td v-if="invoice.taxType == 'fixed'" class="rw-r">
                                        {{ formatAmount(invoice.tax, invoice?.currency || $store.state.Settings?.currency) }}
                                    </td>
                                    <td class="rw-r" v-else>{{ invoice.tax }}%</td>
                                </tr>
                                <tr class="pt4">
                                    <td></td>
                                    <td></td>
                                    <th class="rw-r">Total</th>
                                    <td class="rw-r">
                                        {{
                                            formatAmount(
                                                invoice.totalAmount,
                                                invoice?.currency || $store.state.Settings?.currency
                                            )
                                        }}
                                    </td>
                                </tr>

                                <tr v-if="invoice?.deposit && invoice?.deposit > 0">
                                    <td></td>
                                    <td :style="'border-bottom:1px solid' + color + ';'"></td>
                                    <th class="rw-r" :style="'border-bottom:1px solid' + color + ';'">Amount Paid</th>
                                    <th class="rw-r" :style="'color: ' + color + '; border-bottom:1px solid' + color + ';'">
                                        {{
                                            formatAmount(
                                                invoice?.deposit || 0,
                                                invoice?.currency || $store.state.Settings?.currency
                                            )
                                        }}
                                    </th>
                                </tr>
                                <tr v-if="invoice?.deposit && invoice?.deposit > 0">
                                    <td></td>
                                    <td></td>
                                    <th class="rw-r text-danger">Outstanding</th>
                                    <th class="rw-r text-danger">
                                        {{
                                            formatAmount(
                                                invoice.totalAmount - (invoice?.deposit || 0),
                                                invoice?.currency || $store.state.Settings?.currency
                                            )
                                        }}
                                    </th>
                                </tr>
                            </tfoot>
                        </table>
                    </section>

                    <div class="clear-both"></div>

                    <section class="lh-copy pv3 mt4" style="border-top: 1px solid #e3e8ee">
                        <div v-if="invoice.notes">
                            <p :style="'color: ' + color">Notes</p>
                            <p>{{ invoice.notes ? invoice.notes : '' }}</p>
                        </div>

                        <div>
                            <p :style="'color: ' + color">Terms</p>
                            <p>
                                All amounts are in {{ getCurrencyName(invoice?.currency || 'NGN') }}. Please make the payment
                                before {{ formatDate(invoice.dateDue) }}.
                            </p>
                        </div>
                    </section>
                </div>
            </div>
        </div>
        <div v-if="invoice?.paymentInfo" class="box-border mv4" style="background: #fbfcfe">
            <div class="flex justify-between box-border-bottom pa3">
                <div>Previous Payment(s)</div>
            </div>
            <div
                v-for="(data, index) of invoice.paymentInfo"
                v-bind:key="index"
                class="pa3"
                style="border-bottom: 1px solid #e3e8ee"
            >
                <div class="flex justify-between">
                    <div class="w-30">{{ data.account }}</div>
                    <div class="w-30 b">
                        {{ formatAmount(data.amount, invoice?.currency || $store.state.Settings?.currency) }}
                    </div>
                    <div class="w-30 b">{{ data?.date ? formatDate(data.date) : '' }}</div>
                </div>
            </div>
        </div>
        <div class="box-border mv4" style="background: #fbfcfe">
            <div class="flex justify-between box-border-bottom pa3">
                <div>Selected payment option</div>
            </div>
            <div v-for="item of invoice.bankDetails" v-bind:key="item.bankAccountNumber" class="pa3">
                <div class="flex flex-wrap justify-between items-center pa3 box-border">
                    <div>
                        <div class="pb2 b">Bank Transfer</div>
                        <div class="pb2">Pay by making a bank transfer to the recievers bank account</div>
                        <div class="flex justify-between">
                            <div class="w-30">Bank Name:</div>
                            <div class="w-70 b">{{ item.bankName }}</div>
                        </div>
                        <div class="flex justify-between">
                            <div class="w-30">Account Number:</div>
                            <div class="w-70 b">{{ item.bankAccountNumber }}</div>
                        </div>
                        <div class="flex justify-between">
                            <div class="w-30">Account Name:</div>
                            <div class="w-70 b">{{ item.bankAccountName }}</div>
                        </div>
                    </div>
                    <div class="pt0-l pt3">
                        <img src="imgs/bank.svg" alt="" />
                    </div>
                </div>
            </div>
            <div v-if="invoice?.paystackLink" class="pa3">
                <div class="flex flex-wrap justify-between items-center pa3 box-border copy-text-wrapper">
                    <div>
                        <div class="pb2 b">Paystack</div>
                        <div>Pay through the secure paystack API</div>
                        <div class="flex flex-wrap justify-between items-center pb2">
                            <a :href="invoice.paystackLink" target="_blank" class="pr2">{{ invoice.paystackLink }}</a>
                            <button @click="doCopy" class="pl0 pr0 pb0 pt0">
                                <span class="icon">
                                    <font-awesome-icon icon="fa-solid fa-copy" />
                                </span>
                            </button>
                        </div>
                    </div>
                    <div class="pt0-l pt3">
                        <img :src="require('@/assets/images/paystack.svg')" alt="paystack logo" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { useStore } from 'vuex'
import { computed } from 'vue'
import InvoiceStatus from '@/views/Invoice/widgets/InvoiceStatus'
import { formatAmount, getCurrencyName, getDaysFromToday, formatDate } from '@/utils/lib'

export default {
    name: 'GoldInvoice',
    components: { InvoiceStatus },
    props: {
        invoice: { type: Object },
        color: { type: String },
        font: { type: String },
    },
    setup() {
        const store = useStore()

        const orgData = computed(() => store?.state?.Auth?.userData)
        const orgInitials = computed(() => store?.state?.Auth?.orgInitials)
        const orgImage = computed(() => store?.state?.Auth?.orgImage)
        const userInitials = computed(() => store?.state?.Auth?.userInitials)

        return {
            orgData,
            orgInitials,
            orgImage,
            userInitials,
            formatAmount,
            getCurrencyName,
            getDaysFromToday,
            formatDate,
        }
    },
}
</script>
<style scoped>
body {
    padding: 0;
    margin: 0;
    box-sizing: border-box !important;
}
.inv-table {
    margin-top: 40px;
    width: 100%;
    border-collapse: collapse;
}
.inv-th {
    text-align: left;
}
.inv-th th {
    padding-bottom: 20px;
}
.inv-tbody tr td {
    padding: 20px 0;
}
.inv-tfoot tr td {
    padding: 10px 0;
}
.deskView{
    float: right;
}
.clear-both{
    clear: both;
}

@media screen and (max-width: 520px) {
    .bill_to {
        flex-wrap: wrap;
    }
    .mbCost{
        padding: 0 30px;
    }
    .mbCosts{
        padding: 0 0 0 30px;
    }
}
@media screen and (max-width: 660px) {
    .longText{
    white-space: nowrap; 
    overflow: hidden; 
    text-overflow: ellipsis;
}}
</style>
