<template>
    <div class="invoice-container">
        <div class="box-border pv4" style="background: #fbfcfe">
            <div class="w-90-l w-100 center box-border">
                <div class="pa4">
                    <div class="flex justify-between mb4 flxRev">
                        <div class="aligned-right" style="order: 2">
                            <div class="mb2">
                                <p class="b" style="font-size: 20px">INVOICE</p>
                                <p># {{ invoice.number }}</p>
                            </div>
                            <div>
                                <p class="b">Balance Due</p>
                                <p class="b text-danger" style="font-size: 16px">
                                    {{
                                        formatAmount(
                                            invoice.totalAmount - (invoice?.deposit || 0),
                                            invoice?.currency || $store.state.Settings?.currency
                                        )
                                    }}
                                </p>
                            </div>
                        </div>
                        <div style="order: 1">
                            <p class="b" :style="'color: ' + color">{{ invoice.business?.name }}</p>
                            <p>{{ invoice.business?.address }}</p>
                            <p>{{ invoice.business?.email }}</p>
                            <p>{{ invoice.business?.phone }}</p>
                            <p v-if="invoice.business?.tin">TIN: {{ invoice.business?.tin }}</p>
                        </div>
                    </div>
                    <div class="flex flex-wrap justify-between mb4 flxRev2">
                        <div>
                            <p>Bill to:</p>
                            <p class="b" :style="'color: ' + color">{{ invoice.customer?.name }}</p>
                            <p>{{ invoice.customer?.address }}</p>
                            <p>{{ invoice.customer?.country }}</p>
                            <p>{{ invoice.customer?.email }}</p>
                            <p>{{ invoice.customer?.phone }}</p>
                        </div>
                        <div class="flex justify-between w-50 aligned-right fullWidth">
                            <div>
                                <p>Invoice Date</p>
                                <p>Terms</p>
                                <p>Due Date</p>
                            </div>
                            <div>
                                <p>{{ formatDate(invoice.dateIssued) }}</p>
                                <p>Due on Reciept</p>
                                <p>{{ formatDate(invoice.dateDue) }}</p>
                            </div>
                        </div>
                    </div>

                    <div v-if="invoice?.description && invoice.description != ''" class="mb3 b">
                        {{ invoice.description }}
                    </div>

                    <section class="scrollmenu">
                        <table class="inv4-table">
                            <thead>
                                <tr :style="'color: white; background-color:' + color + ';'">
                                    <th style="padding: 0 20px">#</th>
                                    <th class="tl">Item & description</th>
                                    <th class="rw-r">Qty</th>
                                    <th class="rw-r" style="padding: 0 10px">Rate</th>
                                    <th class="rw-r pa2 pr3">Amount</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr
                                    v-for="(item, index) in invoice.items"
                                    v-bind:key="item.id"
                                    style="border-bottom: 1px solid #e3e8ee"
                                >
                                    <td class="w-10 tc">{{ index + 1 }}</td>
                                    <td class="w-40-l w-70 "  style="white-space: pre-wrap;">{{ item.name }}</td>
                                    <td class="rw-r">{{ item.quantity }} {{ item.measurement }}</td>
                                    <td class="rw-r" style="padding: 0 10px">{{ item.price }}</td>
                                    <!-- <td v-if="item.discount > 0" class="rw-r pa2 w-50">
                                        {{
                                            formatAmount(
                                                item.price * item.quantity,
                                                invoice.currency || $store.state.Settings?.currency
                                            )
                                        }}
                                    </td>
                                    <td v-else class="rw-r pa2">
                                        {{
                                            formatAmount(
                                                item.price * item.quantity,
                                                invoice?.currency || $store.state.Settings?.currency
                                            )
                                        }}
                                    </td> -->
                                    <td class="rw-r  pa2 w-50" v-if="item.discount > 0">
                                        <div class="text-muted" style="text-decoration: line-through;">
                                            {{
                                                formatAmount(
                                                    item.price * item.quantity,
                                                    invoice.currency || $store.state.Settings?.currency
                                                )
                                            }}
                                        </div>
                                        <div>
                                            {{ formatAmount(item.total, invoice?.currency || $store.state.Settings?.currency) }}
                                        </div>
                                    </td>
                                    <td v-else class="rw-r pa2">
                                        {{
                                            formatAmount(
                                                item.price * item.quantity,
                                                invoice?.currency || $store.state.Settings?.currency
                                            )
                                        }}
                                    </td> 
                                </tr>
                            </tbody>
                        </table>
                    </section>
                    <section class="deskView">
                        <table>
                            <tfoot class="tf4">
                                <tr>
                                    <td></td>
                                    <td></td>
                                    <td class="rw-r b">Subtotal</td>
                                    <td></td>
                                    <td class="rw-r">
                                        {{ formatAmount(invoice.subtotal, invoice?.currency || $store.state.Settings?.currency) }}
                                    </td>
                                </tr>
                                <tr v-if="invoice.hasDiscount">
                                    <td class=""></td>
                                    <td class=""></td>
                                    <td class="rw-r b">Discount</td>
                                    <td></td>
                                    <td class="rw-r" v-if="invoice.discountType == 'fixed'" style="color: #D2042D">
                                        - {{ formatAmount(invoice.discount, invoice?.currency || $store.state.Settings?.currency) }}
                                    </td>
                                    <td class="rw-r" v-else style="color: #D2042D">-{{ invoice.discount }}%</td>
                                </tr>
                                <tr v-if="invoice.hasCommission">
                                    <td class=""></td>
                                    <td class=""></td>
                                    <td class="rw-r b">Commission</td>
                                    <td></td>
                                    <td class="rw-r" v-if="invoice.commissionType == 'fixed'">
                                        {{
                                            formatAmount(invoice.commission, invoice?.currency || $store.state.Settings?.currency)
                                        }}
                                    </td>
                                    <td v-else class="rw-r">{{ invoice.commission }}%</td>
                                </tr>
                                <tr v-if="invoice.hasShipping">
                                    <td></td>
                                    <td></td>
                                    <td class="rw-r b">Shipping</td>
                                    <td></td>
                                    <td v-if="invoice.shippingType == 'fixed'" class="rw-r">
                                        {{ formatAmount(invoice.shipping, invoice?.currency || $store.state.Settings?.currency) }}
                                    </td>
                                    <td v-else class="rw-r">{{ invoice.shipping }}%</td>
                                </tr>
                                <tr v-if="invoice.hasOthersCharges">
                                    <td></td>
                                    <td></td>
                                    <td class="rw-r b">{{ invoice.others || 'Other Charges' }}</td>
                                    <td></td>
                                    <td class="rw-r">
                                        {{
                                            formatAmount(
                                                invoice.othersCharges,
                                                invoice?.currency || $store.state.Settings?.currency
                                            )
                                        }}
                                    </td>
                                </tr>
                                <tr v-if="invoice.hasTax">
                                    <td></td>
                                    <td></td>
                                    <td class="rw-r b">Tax</td>
                                    <td></td>
                                    <td v-if="invoice.taxType == 'fixed'" class="rw-r">
                                        {{ formatAmount(invoice.tax, invoice?.currency || $store.state.Settings?.currency) }}
                                    </td>
                                    <td class="rw-r" v-else>{{ invoice.tax }}%</td>
                                </tr>
                                <tr style="border-bottom: 1px solid #e3e8ee">
                                    <td></td>
                                    <td></td>
                                    <td class="rw-r b">Total</td>
                                    <td></td>
                                    <td class="rw-r">
                                        {{
                                            formatAmount(
                                                invoice.totalAmount,
                                                invoice?.currency || $store.state.Settings?.currency
                                            )
                                        }}
                                    </td>
                                </tr>
                                <tr class="b" v-if="invoice?.deposit && invoice?.deposit > 0">
                                    <td></td>
                                    <td></td>
                                    <td class="rw-r">Amount Paid</td>
                                    <td></td>
                                    <td class="rw-r">
                                        {{
                                            formatAmount(
                                                invoice?.deposit || 0,
                                                invoice?.currency || $store.state.Settings?.currency
                                            )
                                        }}
                                    </td>
                                </tr>
                                <tr class="b" v-if="invoice?.deposit && invoice?.deposit > 0">
                                    <td></td>
                                    <td></td>
                                    <td class="rw-r text-danger">Balance Due</td>
                                    <td></td>
                                    <td class="rw-r text-danger">
                                        {{
                                            formatAmount(
                                                invoice.totalAmount - (invoice?.deposit || 0),
                                                invoice?.currency || $store.state.Settings?.currency
                                            )
                                        }}
                                    </td>
                                </tr>
                            </tfoot>
                        </table>
                    </section>

                    <div class="clear-both"></div>

                    <div class="pt4">
                        <div class="pb2">
                            <p class="b">Notes</p>
                            <p>{{ invoice.notes ? invoice.notes : '' }}</p>
                        </div>
                        <div>
                            <p class="b">Terms & Conditions</p>
                            <p>
                                All amounts are in {{ getCurrencyName(invoice?.currency || 'NGN') }}. Please make the payment
                                before {{ formatDate(invoice.dateDue) }}.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div v-if="invoice?.paymentInfo" class="box-border mv4" style="background: #fbfcfe">
        <div class="flex justify-between box-border-bottom pa3">
            <div>Previous Payment(s)</div>
        </div>
        <div v-for="(data, index) of invoice.paymentInfo" v-bind:key="index" class="pa3" style="border-bottom: 1px solid #e3e8ee">
            <div class="flex justify-between">
                <div class="w-30">{{ data.account }}</div>
                <div class="w-30 b">
                    {{ formatAmount(data.amount, invoice?.currency || $store.state.Settings?.currency) }}
                </div>
                <div class="w-30 b">{{ data?.date ? formatDate(data.date) : '' }}</div>
            </div>
        </div>
    </div>
    <div class="box-border mv4" style="background: #fbfcfe">
        <div class="flex justify-between box-border-bottom pa3">
            <div>Selected payment option</div>
        </div>
        <div v-for="item of invoice.bankDetails" v-bind:key="item.bankAccountNumber" class="pa3">
            <div class="flex flex-wrap justify-between items-center pa3 box-border">
                <div>
                    <div class="pb2 b">Bank Transfer</div>
                    <div class="pb2">Pay by making a bank transfer to the recievers bank account</div>
                    <div class="flex justify-between">
                        <div class="w-30">Bank Name:</div>
                        <div class="w-70 b">{{ item.bankName }}</div>
                    </div>
                    <div class="flex justify-between">
                        <div class="w-30">Account Number:</div>
                        <div class="w-70 b">{{ item.bankAccountNumber }}</div>
                    </div>
                    <div class="flex justify-between">
                        <div class="w-30">Account Name:</div>
                        <div class="w-70 b">{{ item.bankAccountName }}</div>
                    </div>
                </div>
                <div class="pt0-l pt3">
                    <img src="imgs/bank.svg" alt="" />
                </div>
            </div>
        </div>
        <div v-if="invoice?.paystackLink" class="pa3">
            <div class="flex flex-wrap justify-between items-center pa3 box-border copy-text-wrapper">
                <div>
                    <div class="pb2 b">Paystack</div>
                    <div>Pay through the secure paystack API</div>
                    <div class="flex flex-wrap justify-between items-center pb2">
                        <a :href="invoice.paystackLink" target="_blank" class="pr2">{{ invoice.paystackLink }}</a>
                        <button @click="doCopy" class="pl0 pr0 pb0 pt0">
                            <span class="icon">
                                <font-awesome-icon icon="fa-solid fa-copy" />
                            </span>
                        </button>
                    </div>
                </div>
                <div class="pt0-l pt3">
                    <img :src="require('@/assets/images/paystack.svg')" alt="paystack logo" />
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { useStore } from 'vuex'
import { computed } from 'vue'
import InvoiceStatus from '@/views/Invoice/widgets/InvoiceStatus'
import { formatAmount, getCurrencyName, getDaysFromToday, formatDate } from '@/utils/lib'

export default {
    name: 'PlatinumInvoice',
    components: { InvoiceStatus },
    props: {
        invoice: { type: Object },
        color: { type: String },
        font: { type: String },
    },
    setup() {
        const store = useStore()

        const orgData = computed(() => store?.state?.Auth?.userData)
        const orgInitials = computed(() => store?.state?.Auth?.orgInitials)
        const orgImage = computed(() => store?.state?.Auth?.orgImage)
        const userInitials = computed(() => store?.state?.Auth?.userInitials)

        return {
            orgData,
            orgInitials,
            orgImage,
            userInitials,
            formatAmount,
            getCurrencyName,
            getDaysFromToday,
            formatDate,
        }
    },
}
</script>
<style>
.aligned-right {
    text-align: right;
}

.inv4-table tbody tr td {
    padding: 20px 0;
}

.inv4-table {
    border-collapse: collapse;
    width: 100%;
}
.tf4 tr td {
    padding-top: 20px;
}
.deskView{
    float: right;
}
.clear-both{
    clear: both;
}
@media screen and (max-width: 550px) {
    .flxRev {
        display: block;
    }
    .aligned-right {
        text-align: left;
        margin-bottom: 20px;
        margin-top: 20px;
    }
    .fullWidth {
        width: 100%;
    }
}
</style>
